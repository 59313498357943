// @flow
import { EventLocationSerializer, JobSerializer } from 'services/serializers';
import ReportToContactSerializer from './ReportToContactSerializer';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { ShiftForm } from '../../redux/ducks/CreateEvent/Shifts';
import type { SerializedEventLocation } from './EventLocationSerializer';
import { snakeCase } from 'lodash';
import DetailsSerializer from './v2/DetailsSerializer';
import { checkIsMultiDayShift } from 'helpers/post';
import { get } from 'lodash';
import { computePenalty, totalHours } from '../../helpers/cancellations';

export default class ShiftSerializer {
  static deserialize(shift: SerializedShift, athleticTrainers: any[] = [], eventRate = 0) {
    const zip_code = get(shift, 'event_location.address.zip_code', null);
    const tz = zipcode_to_timezone.lookup(zip_code) || moment.tz.guess();
    const endTime = moment.tz(shift.end_time, tz).format();
    const startTime = moment.tz(shift.start_time, tz).format();

    const result: Object = {
      id: shift.id,
      endTime: endTime,
      shiftCapacity: shift.shift_capacity,
      startTime: startTime,
      emergencyActionPlanId: shift.emergency_action_plan_id,
      reportToContacts: shift.shift_contacts?.map(ReportToContactSerializer.deserialize),
      schedule: shift.schedule,
      approxHours: shift.approx_hours,
      notes: shift.notes,
      responsibilities: shift.responsibilities,
      isMultiDay: checkIsMultiDayShift({
        startTime: startTime,
        endTime: endTime,
      }),
      contractInfo: shift.contract_info || {},
      cancelled: shift.cancelled,
      instructions: shift.instructions,
      payRate: shift.pay_rate || eventRate,
      rateTypeId: shift.rate_type_id?.toString(),
      details: DetailsSerializer.deserialize(shift.details),
      boostedAt: shift.boosted_at,
      shiftSum: 0,
    };

    if (shift.no_fields) {
      result.countType = 'noFields';
      result.countValue = shift.no_fields;
    } else if (shift.no_mats) {
      result.countType = 'noMats';
      result.countValue = shift.no_mats;
    } else if (shift.no_courts) {
      result.countType = 'noCourts';
      result.countValue = shift.no_courts;
    } else if (shift.no_rinks) {
      result.countType = 'noRinks';
      result.countValue = shift.no_rinks;
    }

    if (shift.event_location) {
      result['eventLocation'] = EventLocationSerializer.deserialize(shift.event_location);
    }

    if (shift.jobs) {
      result['jobs'] = {
        byId: {},
        ids: [],
      };

      shift.jobs &&
        shift.jobs.forEach((data) => {
          const job = JobSerializer.deserialize(data, athleticTrainers);
          result.jobs.byId[job.id] = job;
          result.jobs.ids.push(job.id);
        });

      result['shiftSum'] = Object.values(result.jobs.byId).reduce((acc, job) => {
        const rateType = job.rateTypeId ? 'flat' : 'hr';
        if (job.payment) {
          return acc + job.payment.eoTotalCharge;
        } else if (job.cancelledAt) {
          const penalty = computePenalty(job, tz, rateType);
          return acc + penalty;
        } else if (['confirmed', 'checked_in', 'paid'].includes(job.currentState)) {
          const totalPay =
            rateType === 'hr'
              ? get(job, 'payRate', 0) * totalHours(job)
              : get(job, 'payRate', 0);
          return acc + totalPay;
        }
        return acc;
      }, 0);
    }

    return result;
  }

  static serialize(shift: ShiftForm): SerializedShift {
    let timeZone =
      zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();

    const result = {
      start_time: moment.tz(shift.start, timeZone).tz('UTC').format(),
      end_time: moment.tz(shift.end, timeZone).tz('UTC').format(),
      shift_capacity: shift.capacity,
      cancelled: shift.cancelled,
      event_location_attributes: EventLocationSerializer.serialize(shift.eventLocation),
      shift_contacts_attributes: shift.reportToContacts.map(ReportToContactSerializer.serialize),
      no_fields: '',
      no_mats: '',
      no_courts: '',
      no_rinks: '',
      schedule: shift.schedule,
      approx_hours: shift.approxHours,
      notes: shift.notes,
      responsibilities: shift.responsibilities,
      contract_info: shift.contractInfo || {},
    };

    if (shift.countType) {
      result[snakeCase(shift.countType)] = shift.countValue;
    }

    if (shift.id) {
      result.id = shift.id;
    }

    if (shift.cancelReason) {
      result.cancel_reason = shift.cancelReason;
    }

    if (shift.locationCancelled) {
      result.location_cancelled = true;
    }

    return result;
  }
}

export type SerializedShift = {
  id?: ?number,
  start_time: string,
  end_time: string,
  shift_capacity: number,
  jobs?: Object[],
  event_location_attributes: SerializedEventLocation,
  shift_contacts_attributes: Object[],
  emergency_action_plan_id?: ?number,
  no_fields: ?number,
  no_mats: ?number,
  no_courts: ?number,
  no_rinks: ?number,
  notes: ?string,
  responsibilities: ?string,
  contractInfo: {
    [key: string]: any,
  },
  details: {
    [key: string]: any,
  },
};
